import axiosInstance from './axios';

const getAllUser = () => {
  return axiosInstance.get('user/all/').then((res) => {
    return res.data.users;
  });
};
const getAllGroup = () => {
  return axiosInstance.get('user/group/').then((res) => {
    return res.data.groups;
  });
};
const getAllPermission = () => {
  return axiosInstance.get('user/permission/').then((res) => {
    return res.data.permissions;
  });
};

const getAllUserFilter = ({ department, from, to }) => {
  return axiosInstance
    .post(`user/all/?department=${department}&from=${from}&to=${to}`)
    .then((res) => {
      return res.data.users;
    });
};

const addUser = (data) => {
  return axiosInstance
    .post('user/add/', {
      first_name: data.first_name,
      last_name: data.last_name,
      display_name: data.display_name,
      idnumber: data.idnumber,
      email: data.email,
      phone: data.phone,
      department: data.department,
      group: data.group,
    })
    .then((res) => {
      return res.data;
    });
};
const addGroup = (data) => {
  return axiosInstance
    .post('user/group/add/', {
      name: data.name,
      permissions: data.permissions,
    })
    .then((res) => {
      return res.data;
    });
};

const deleteUser = ({ id }) => {
  return axiosInstance.delete(`user/${id}/`).then((res) => {
    return res.data;
  });
};
const deleteGroup = ({ id }) => {
  return axiosInstance.delete(`user/${id}/group/`).then((res) => {
    return res.data;
  });
};

const updateGroup = ({ id, name }) => {
  return axiosInstance
    .patch(`user/${id}/group/`, { name: name })
    .then((res) => {
      return res.data;
    });
};

const getUserById = (id) => {
  return axiosInstance.get(`user/${id}/`).then((res) => {
    return res.data.user;
  });
};

const updateUserPassword = ({ id, password }) => {
  return axiosInstance
    .patch(`user/${id}/password/`, { password: password })
    .then((res) => {
      return res.data;
    });
};

const updateUser = ({ id, status, ...data }) => {
  return axiosInstance.patch(`user/${id}/change/`, { ...data }).then((res) => {
    return res.data;
  });
};
const userByDepartment = ({ id }) => {

  return axiosInstance.get(`user/${id}/department/`).then((res) => {
    return res.data['data'];
  });
};
const removeUserDepartment = ({ user_id, department_id }) => {
  return axiosInstance
    .delete(`user/${user_id}/department/${department_id}/remove/`)
    .then((res) => {
      return res.data;
    });
};
const addUserDepartment = ({ user_id, department_ids }) => {
  return axiosInstance
    .patch(`user/${user_id}/add-departments/`, {
      department_ids,
    })
    .then((res) => {
      return res.data;
    });
};

const UserServices = {
  getAllUser,
  addUser,
  deleteUser,
  getUserById,
  updateUser,
  updateUserPassword,
  getAllUserFilter,
  getAllGroup,
  addGroup,
  deleteGroup,
  updateGroup,
  getAllPermission,
  userByDepartment,
  removeUserDepartment,
  addUserDepartment,
};

export default UserServices;
